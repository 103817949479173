input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.nojobs {
  h2 {
    font-weight: 700;
    text-align: center;
    padding: 0 10px;
    margin: auto;
    font-size: 18px;
    color: #88bcd8;
  }

  p {
    font-size: 14px;
    color: #00000060;
    width: 80%;
    margin: auto;
    text-align: center;
    margin-top: 10px;
  }
}

.beacon {
  display: inline-block;
  position: relative;
  background-color: #065b53;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  animation: grow 0.4s 1 linear;

  &::before {
    position: absolute;
    content: '';
    height: 6px;
    width: 6px;
    left: 0;
    top: 0;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 4px #6dd40050;
    animation: active 2s infinite linear;
  }
}

.sign_out {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  background-color: #d32752;
  color: #fff;
  margin: 100px auto 20px;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 400;
}

.location_access_error {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  text-align: center;
  margin: 5px auto;
  color: #eb7961;

  button {
    text-decoration: underline;
    color: #000000;
  }
}

@keyframes grow {
  0% {
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes active {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(2.5);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
