.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff48;
  z-index: 3000;
  backdrop-filter: blur(3px);

  p {
    font-size: 11px;
  }
}

.loader {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;

  &_inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }

    div {
      position: absolute;
      animation: loading 1s linear infinite;
      width: 80px;
      height: 80px;
      top: 10px;
      left: 10px;
      border-radius: 50%;
      box-shadow: 0 3px 0 0 #4ec4ff;
      transform-origin: 40px 42px;
      box-sizing: content-box;
    }
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
